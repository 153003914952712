body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'TheSeasons';
  src: local('TheSeasons'), url("./fonts/TheSeasons/The Seasons Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'Blanka';
  src: local('Blanka'), url("./fonts/Blanka/Blanka-Regular.otf") format('truetype');
}

.home-invest {
  font-size: 6em; /* Tamanho base */
  font-family:'TheSeasons';
   text-align: center;
   white-space: nowrap;
}

.home-invest-subtitulo{
  font-size: 1.4rem;
  color:#2B2B2B;
}
/* Media queries para diferentes tamanhos de tela */
@media (min-width: 768px) {
  .home-invest {
    font-size: 7em; /* Tamanho para telas maiores */
  }
  .home-invest-subtitulo{
    font-size: 1.4rem;
  }
}

@media (min-width: 1200px) {
  .home-invest {
    font-size: 8em; /* Tamanho para telas ainda maiores */
  }
  .home-invest-subtitulo{
    font-size: 2rem;
  }
}


